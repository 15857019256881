import React from "react"
import { graphql } from "gatsby"
import { Link as GatsbyLink } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Center,HStack,Box, Link, Text, Heading, Image, Divider,
  Flex,
  IconButton,
  Tooltip,
  Spacer
   } from "@chakra-ui/react"
import { 
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowRightIcon,
  ArrowLeftIcon } from '@chakra-ui/icons'
import Layout from '../components/Layout.js';
import Parser from "html-react-parser"
import Seo from '../components/seo_metadata.js';

const BlogIndex = ({data, path, pageContext: { pageNumber,pageCount,firstPagePath,lastPagePath,nextPagePath, previousPagePath,slugname }}) => {

  return (
    <Layout>
       <Seo 
         title={slugname+"一覧|パチンカーズネット"} 
         description={"パチンカーズネット"+slugname+"一覧です"}
      />
      <Box bgGradient="repeating-linear(135deg, transparent, transparent 1px, rgba(216, 228, 227, 1) 1px, rgba(216, 228, 227, 1) 2px)">
        <Center p={"2vw"}>
          <Heading as="h2" fontSize="4vw" isTruncated>
            {slugname}
          </Heading>
        </Center>
      </Box>
    {/* <pre style={{ backgroundColor: '#eee', padding: '1em', }} >
      <code>
        {JSON.stringify(data,null,2)}
      </code>
    </pre> */}
    {
      data.allWpPost.nodes.map(post => {
         const id = post.id;
         const title = post.title;
         const uri = post.uri;
         const date = post.date;
         const excerpt = post.excerpt
            .replace("[toc]","")
            .replace("[holl_befor_sentence]","")
            .replace("[pachi_befor_sentence]","");
         const authorname = post.author.node.name;
         let tagnames = post.tags.nodes.map(node =>{
            return node.name;
         });
         
         return (
            
            // <li key={uri}>
            //   <article
            //     className="post-list-item"
            //     itemScope
            //     itemType="http://schema.org/Article"
            //   >
            //     <header>
            //       <h2>
            //         <Link to={uri} itemProp="url">
            //           <span itemProp="headline">{Parser(title)}</span>
            //         </Link>
            //       </h2>
            //       <small>{date}</small>
            //     </header>
            //     {/* <section itemProp="description">{parse(post.excerpt)}</section> */}
            //   </article>
            // </li>
         <>
         <Box p={3} >
            <Divider borderColor="gray"/>
         </Box>
         <Box p={4} display={{ md: "flex" }}>
            {post.featuredImage &&
            <Center>
               
               <Image
               as={GatsbyImage}
               // fluid={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
               image={getImage(post.featuredImage.node.localFile.childImageSharp)}
               borderRadius="lg"
               // boxSize="90vw"
               w={{base:"90vw",md:"30vw"}} 
               h={{base:"50vw",md:"20vw"}}
               />
            </Center>
            }
            <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
               <HStack >
                  {authorname != "admin" ? 
                  <>
                  <Text 
                     p="0.5"
                     borderRadius="md" 
                     bgGradient="repeating-linear(135deg, transparent, transparent 1px, rgba(216, 228, 227, 1) 1px, rgba(216, 228, 227, 1) 2px)" 
                     fontWeight="bold" 
                     textTransform="uppercase"
                     fontSize="sm" 
                     letterSpacing="wide" 
                     color="gray.600" >
                     {authorname}
                  </Text>
                  <Spacer />
                  </>
                  : ""}
                  <Center>
                  {tagnames &&
                     tagnames.map(tagname=> {
                        return (
                        <Text mx={2} fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
                        {tagname}
                        </Text>
                        )
                     })
                  }
                  </Center>
                  <Spacer />
                  <Box>
                     <Text align="right" fontWeight="bold" textTransform="uppercase" fontSize="sm" letterSpacing="wide" color="teal.600" >
                        {date}
                     </Text>
                  </Box>
               </HStack>
               <Link
                  as={GatsbyLink}
                  to={uri}
                  mt={1}
                  display="block"
                  fontSize="lg"
                  lineHeight="normal"
                  fontWeight="semibold"
                  href="#"
               >
                  {title}
               </Link>
               <Text mt={2} color="gray.500">
                  {Parser(excerpt)}
               </Text>
            </Box>
         </Box>
         </>
         )
      })
    }
    {/* {previousPagePath && <Link as={GatsbyLink} to={previousPagePath}>Previous page</Link> }
    {nextPagePath && <Link as={GatsbyLink} to={nextPagePath}>Next page</Link> } */}
    <Center mt={3}><Flex>
      
      {/* <Tooltip label="First Page">
        <IconButton
          onClick={() => gotoPage(0)}
          isDisabled={!canPreviousPage}
          icon={<ArrowLeftIcon h={3} w={3} />}
          mr={4}
        />
      </Tooltip> */}
        <Link as={GatsbyLink} to={firstPagePath}>
          <Tooltip label="First Page">
            <IconButton
              // onClick={() => gotoPage(0)}
              isDisabled={!previousPagePath}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
        </Link>
        <Link as={GatsbyLink} to={previousPagePath}>
          <Tooltip label="Previous1 Page">
            <IconButton
              // onClick={previousPage}
              isDisabled={!previousPagePath}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Link>
        <Box m={2}>
          <Text flexShrink="0">
            {" "}
            <Text fontWeight="bold" as="span">
              {pageNumber}
            </Text>
            {" "}
            /
            {" "}
            <Text fontWeight="bold" as="span">
              {pageCount}
            </Text>
          </Text>
        </Box>

        <Link as={GatsbyLink} to={nextPagePath}>
          <Tooltip label="Next Page">
            <IconButton
              // onClick={previousPage}
              isDisabled={!nextPagePath}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
        </Link>
        
        <Link as={GatsbyLink} to={lastPagePath}>
          <Tooltip label="Last Page">
            <IconButton
              // onClick={() => gotoPage(pageCount - 1)}
              isDisabled={!nextPagePath}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Link>
        
    </Flex></Center>

    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query($offset: Int!, $postsPerPage: Int!, $slug: String!) {
    allWpPost(
      filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ){
      nodes {
        id
        title
        uri
        date(formatString: "YYYY.MM.DD")
        excerpt
        terms {
          nodes {
            name
            slug
            nodeType
          }
        }
        author {
          node {
            name
            slug
          }
        }
        tags {
          nodes {
            name
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
`